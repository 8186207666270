import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import tw from 'tailwind.macro';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';
import Button from '../components/button';
import Grid from '../components/grid';
import Layout from '../components/layout';
import LeadText from '../components/leadText';
import Panel from '../components/panel';
import SEO from '../components/seo';

const fullConfig = resolveConfig(tailwindConfig);

const Section = tw.section``;

const Projects = ({ data, location }) => {
  const parentTitle = '';
  const pageTitle = 'Projects';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} />

      <Section>
        <BackgroundImage
          fluid={[
            `linear-gradient(${fullConfig.theme.colors.maroon.translucent} 15%,transparent)`,
            data.activities.childImageSharp.fluid,
          ]}
          css={tw`relative pt-20 pb-32`}
        >
          <div className="container" css={tw`mb-10`}>
            <h2 className="text-shadow-lg" css={tw`mt-0 text-white`}>
              Current projects
            </h2>
            <LeadText className="text-shadow" css={tw`text-white`}>
              The Centre for Social Justice is anchored in the triple
              objectives of responsive research, teaching and social impact. The
              Social Justice M-Plan is the flagship programme of Law Trust the
              Chair in Social Justice.
            </LeadText>
          </div>
          <div className="container">
            <Grid>
              <Panel css={tw`flex flex-col content-start items-start p-10`}>
                <h3 css={tw`mt-0`}>The Social Justice Musa Plan (M-Plan)</h3>
                <LeadText>
                  A social justice accelerator programme. Its theory of change
                  is that poverty and inequality are systems problems and
                  require a systems approach to change.
                </LeadText>
                <Button to="/projects/m-plan/" css={tw`mt-auto`}>
                  Explore &rarr;
                </Button>
              </Panel>
              <Panel css={tw`flex flex-col content-start items-start p-10`}>
                <h3 css={tw`mt-0`}>#ACTION4INCLUSION campaign</h3>
                <LeadText>
                  A campaign to address the plight of historic debt owed to
                  universities by students from poor and working-class families.
                </LeadText>
                <Button to="/projects/action4inclusion/" css={tw`mt-auto`}>
                  Explore &rarr;
                </Button>
              </Panel>
              <Panel css={tw`flex flex-col content-start items-start p-10`}>
                <h3 css={tw`mt-0`}>
                  Social Justice and COVID-19 Policy and Relief Monitoring
                  Alliance (SCOPRA)
                </h3>
                <LeadText>
                  A project to regularly track all COVID-19 policies and relief
                  measures to assess and ensure compliance with the equality
                  duty and related social justice commitments, other human
                  rights obligations and democratic governance dictates.
                </LeadText>
                <Button to="/projects/scopra/" css={tw`mt-auto`}>
                  Explore &rarr;
                </Button>
              </Panel>
              <Panel css={tw`flex flex-col content-start items-start p-10`}>
                <h3 css={tw`mt-0`}>Data Analytics</h3>
                <LeadText>
                  We are strengthening state capacity to leverage data analytics
                  to pass laws and policies that reduce poverty and inequality
                  and to embrace a culture of data based decision-making. The
                  focus is assessing and predicting the likely social justice
                  impact of planned policies, legislation and plans as well as
                  those already in operation.
                </LeadText>
                <Button to="/projects/data-analytics/" css={tw`mt-auto`}>
                  Explore &rarr;
                </Button>
              </Panel>
              <Panel css={tw`flex flex-col content-start items-start p-10`}>
                <h3 css={tw`mt-0`}>Social Justice Café</h3>
                <LeadText>
                  The aim of the cafés is to engage with young people on social
                  justice issues and human rights-inspired democracy and action
                  for inclusion.
                </LeadText>
                <Button to="/projects/social-justice-cafe/" css={tw`mt-auto`}>
                  Explore &rarr;
                </Button>
              </Panel>
              <Panel css={tw`flex flex-col content-start items-start p-10`}>
                <h3 css={tw`mt-0`}>Everyday Justice</h3>
                <LeadText>
                  A community education program to develop the capacity of
                  individuals and groups of all ages through their actions, the
                  capacity of communities, to improve their quality of life.
                </LeadText>
                <Button to="/projects/everyday-justice/" css={tw`mt-auto`}>
                  Explore &rarr;
                </Button>
              </Panel>              
              <Panel css={tw`flex flex-col content-start items-start p-10`}>
                <h3 css={tw`mt-0`}>Dear Presidents campaign</h3>
                <LeadText>
                  A campaign to inspire young people to find and use their voice
                  to shape and lead for democracy that works for them.
                </LeadText>
                <Button to="/projects/dear-presidents/" css={tw`mt-auto`}>
                  Explore &rarr;
                </Button>
              </Panel>
            </Grid>
          </div>
        </BackgroundImage>
      </Section>
    </Layout>
  );
};

export default Projects;

export const pageQuery = graphql`
  query {
    activities: file(relativePath: { eq: "home-activities.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
